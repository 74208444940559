import React from "react"
import { graphql, Link, StaticQuery } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import "../utils/normalize.css"
import "../utils/css/screen.css"
import { getSrc } from "gatsby-plugin-image"

const BlogIndex = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title

  return (
    <Layout title={siteTitle} location={location}>
      <SEO
        title="Home"
      />
      <header className="page-head">
        <h2 className="page-head-title">
          custom blackwork tattoos
        </h2>
      </header>
      <div className="post-feed">
        <MainCard thumbnail={data.aboutThumbnail} title={"about"} to={"/about"}/>
        <MainCard thumbnail={data.tattoosThumbnail} title={"tattoos"} to={"/tattoos"}/>
        <MainCard thumbnail={data.designsThumbnail} title={"designs"} to={"/designs"} className={"designs-thumbnail"}/>
        <MainCard thumbnail={data.otherThumbnail} title={"other"} to={"/other"}/>
        <MainCard thumbnail={data.bookingThumbnail} title={"contact & booking"} to={"/contact"} style={{backgroundPositionY: "top"}}/>
      </div>
    </Layout>
  )
}

const MainCard = ({ thumbnail, title, to, className = "", style = {} })=>{
  return <article
    className={`post-card with-image ${className}`}
    style={
      thumbnail && {
        backgroundImage: `url(${
          getSrc(thumbnail.childImageSharp.gatsbyImageData)
        })`,
        ...style
      }
    }
  >
    <Link to={to} className="post-card-link">
      <div className="post-card-content">
        <h2 className="post-card-title">
          {title}
        </h2>
      </div>
    </Link>
  </article>
}

const indexQuery = graphql`{
    site {
        siteMetadata {
            title
            description
        }
    }
    allMarkdownRemark(sort: {fields: [frontmatter___date], order: DESC}) {
        edges {
            node {
                excerpt
                fields {
                    slug
                }
                frontmatter {
                    date(formatString: "MMMM DD, YYYY")
                    title
                    description
                    thumbnail {
                        childImageSharp {
                            gatsbyImageData(layout: FULL_WIDTH)
                        }
                    }
                }
            }
        }
    }
    aboutThumbnail: file(absolutePath: {regex: "/about-thumbnail.JPG/"}) {
        childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
        }
    }
    tattoosThumbnail: file(absolutePath: {regex: "/tattoos-thumbnail.JPG/"}) {
        childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
        }
    }
    designsThumbnail: file(absolutePath: {regex: "/designs-thumbnail.JPG/"}) {
        childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
        }
    }
    otherThumbnail: file(absolutePath: {regex: "/other-thumbnail.JPG/"}) {
        childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
        }
    }
    bookingThumbnail: file(absolutePath: {regex: "/booking-thumbnail2.JPG/"}) {
        childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
        }
    }
}
`

const Index = props => (
  <StaticQuery
    query={indexQuery}
    render={data => (
      <BlogIndex data={data} {...props} />
    )}
  />
)
export default Index
